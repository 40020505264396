import React, { Component } from "react";
import { Helmet } from "react-helmet";
class Gtm extends Component {
    render() {
        return (
            <Helmet>
                <script innerHTML='window.digitalData = window.digitalData || [];
digitalData.push({
   "event": "Page Loaded",
   "page": {
        "analyticsId":"UA-91649088-87",
        "brandName":"Neutrogena",
        "country":"pl",
        "flattened":true,
        "hostname":"www.neutrogena.com.pl",
        "language":"pl",
        "siteTier":"low",
   } 
});' />
                <script innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
 })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');" />
            </Helmet>
       
        );
    }
}
export default Gtm;